<template>
  <div class="guide-container line-height-1-6">
    <h1 class="second-colour">
      What is the Latest Meme Coin Craze? $PEPE and $SPONGE Explained
    </h1>

    <img
      src="@/assets/imgs/Articles/spongebob-square-pants-banner.webp"
      alt="spongebob-square-pants-banner"
    />

    <br />

    <!-- <p class="colour-5"> -->
    <p class="fourth-colour">
      An obvious, but important disclaimer: Information here is for educational
      purposes only and is not financial advice. Do your own research before
      making any investments, as the value of cryptocurrencies can fluctuate.
    </p>

    <br />
    <br />

    <h2 class="second-colour">
      Super duper quick answers
    </h2>
    <img
      src="@/assets/imgs/Articles/office-space-manager-get-to-the-point.webp"
      alt="office-space-manager-get-to-the-point"
    />

    <ol class="padding-left-0">
      <li>
        <h3>What is the Sponge ($SPONGE) coin price</h3>
        <em class="fourth-colour">
          Latest price of $SPONGE can be seen on CoinMarketCap
          <a
            class="fourth-colour"
            href="https://coinmarketcap.com/currencies/sponge/"
            target="_blank"
            >here</a
          >
        </em>
        <!-- <hr> -->
      </li>

      <li>
        <h3>What is the Pepe (PEPE) coin price</h3>
        <em class="fourth-colour">
          Latest price of PEPE can be seen on Binance
          <a
            class="fourth-colour"
            href="https://www.binance.com/en/price/pepe"
            target="_blank"
            >here</a
          >
        </em>
      </li>

      <li>
        <h3>How to buy Spongebob meme coin?</h3>
        <em class="fourth-colour">
          You can buy Sponge using the Coinbase Wallet. Check out the steps
          <a
            class="fourth-colour"
            href="https://www.coinbase.com/how-to-buy/sponge"
            target="_blank"
            >here</a
          >
        </em>
      </li>

       <li>
        <h3>How to buy Pepe (PEPE) coin?</h3>
        <em class="fourth-colour">
          Pepe is listed on the Binance exchange. You can buy it
          <a
            class="fourth-colour"
            href="https://www.binance.com/en/price/pepe?ref=G6UDTO47"
            target="_blank"
            >here</a
          >
        </em>
      </li>

      <!-- - 


- next meme coin
- pepe coin binance listing -->
    </ol>

    <br />
    <br />

    <h2 class="second-colour">
      Meme Coin Madness
    </h2>
    <p>
      Hey there crypto fans, have you been keeping an eye on the meme coin
      madness lately? It's been a wild ride, with PepeCoin ($PEPE) soaring
      nearly 6,000% in just a few days. I mean, every time you think it can't
      possibly go any higher, it does!
    </p>
    <p>
      But don't get too distracted by the PepeCoin hype, because there's a new
      meme coin on the block; $SPONGE, and it's SpongeBob Squarepants-themed!
      Since launching on May 4th 2023, $SPONGE had jumped almost 550%. That's
      right, SpongeBob is making waves in the crypto world.
    </p>

    <img
      src="@/assets/imgs/Articles/spongebob-price-chart-coinmarketcap.webp"
      alt="spongebob-price-chart-coinmarketcap"
    />

    <p>
      Check out the latest price of $SPONGE on CoinMarketCap
      <a href="https://coinmarketcap.com/currencies/sponge/" target="_blank"
        >here</a
      >
      📈
    </p>

    <br />
    <br />

    <h2 class="second-colour">
      PepeCoin: The Defying Expectations Meme Coin
    </h2>

    <img
      src="@/assets/imgs/Articles/pepe-coin-green-hero-banner.webp"
      alt="pepe-coin-green-hero-banner"
    />
    <p>
      Let's talk about PepeCoin for a minute, though. This thing has been
      defying expectations left and right. It's gained more than 10x in value
      since April 29th! At one point, it was even out-trading both $DOGE and
      $SHIB, the two leading meme coins by market cap. That's some serious meme
      power.
    </p>

    <img
      src="@/assets/imgs/Articles/pepe-coin-price-chart-coinmarketcap.webp"
      alt="pepe-coin-price-chart-from-coinmarketcap"
    />

    <p @click="openBinanceRegisterPage">
      Pepe Coin can be purchased on Binance <u> here</u> 🛒.
    </p>

    <p>
      See the latest price of Pepe coin on CoinMarketCap
      <a href="https://coinmarketcap.com/currencies/pepe/" target="_blank"
        >here</a
      >
      📈
    </p>

    <p>
      Now, some people are wondering if PepeCoin has hit its peak. It's hard to
      say, but at these levels, traders definitely need to be careful. The fall
      from the top could be steep. So, for those of you who are more
      conservative, it might be too late to jump on the PepeCoin bandwagon.
    </p>

    <br />
    <br />

    <h2 class="second-colour">
      The Popularity of SpongeBob
    </h2>
    <img
      src="@/assets/imgs/Articles/spongebob-squarepants-global-demand-chart.webp"
      alt="spongebob-square-pants-global-demand-chart"
    />

    <p>
      That's where $SPONGE comes in. This token is riding on the popularity of
      the SpongeBob meme, and let's face it, who doesn't love SpongeBob? The
      show was a staple for many of us growing up, and now that we're in the
      crypto world, we can relive those memories through memes.
    </p>
    <p>
      $SPONGE has already gained a lot of attention, with traders and analysts
      predicting it could be the next big meme coin. It's already listed on
      CoinMarketCap, and some people are saying it could deliver 1,000x returns
      for early investors. That's some serious Krabby Patty power!
    </p>

    <br />
    <br />

    <h2 class="second-colour">
      How to Get Involved in $SPONGE
    </h2>

    <img
      src="@/assets/imgs/Articles/spongebob-pointing-finger.webp"
      alt="spongebob-square-pants-pointing-finger"
    />

    <p>
      So, if you're feeling adventurous and want to get in on the meme coin
      madness, head on over to
      <a href="https://uniswap.org/" target="_blank">Uniswap</a> and grab some
      $SPONGE. It has a fixed supply of 40,400,000,000 tokens and is structured
      to promote liquidity. Plus, there are no taxes on trading. It's like
      getting a free Krusty Krab burger!
    </p>
    <p>
      It's an exciting time to be in the crypto world. Who knows what meme coin
      will come next? Maybe we'll see a PatrickCoin or a SquidwardCoin. The
      possibilities are endless, and that's what makes it so much fun. Just
      remember to trade smart and don't get too caught up in the hype. Happy
      trading, everyone!
    </p>

    <br />

    <br />
    <h2 class="second-colour">
      Gimmie the step by step by step by step dummies guide!
    </h2>

    <img
      src="@/assets/imgs/Articles/hide-the-pain-harold-learning-crypto-is-great.webp"
      alt="hide-the-pain-harold-learning-crypto-is-great"
    />

    <p>
      If you're super fresh to crypto, this is the guide for you to get your
      feet wet ->
      <router-link to="/how-to-open-a-binance-account" target="_blank"
        >How to open a Binance account</router-link
      >
    </p>

    <p>
      And once you've got that Binance account set up, next steps are here ->
      <router-link
        to="/how-to-buy-bitcoin-on-binance-with-bank-transfer"
        target="_blank"
        >How To Buy Bitcoin On Binance With Bank Transfer</router-link
      >
    </p>
  </div>
</template>

<script>
import { event } from 'vue-gtag';

export default {
  name: 'PEPEAndSPONGEExplained',

  setup() {
    const openBinanceRegisterPage = () => {
      //Track event in Google Analytics:
      //https://matteo-gabriele.gitbook.io/vue-gtag/
      event('PEPEAndSPONGEExplained_binance_registerAccountLinkClicked', {
        method: 'openBinanceRegisterPage'
      });

      //How to open link in a new tab:
      //https://javascript.plainenglish.io/vue-open-link-in-new-tab-6d4cbc009f0e
      window.open(
        'https://accounts.binance.com/en/register?ref=G6UDTO47',
        '_blank',
        'noreferrer'
      );
    };

    return {
      openBinanceRegisterPage
    };
  }
};
</script>
